import classNames from "classnames";
import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import type { FC } from "react";

import { LoadingProgressBar } from "@/components/loading-progress-bar/LoadingProgressBar";
import { REQUEST_INTERCEPTOR_EVENT_NAME } from "@/components/request-interceptor/RequestInterceptorContainer";
import type { Layout } from "@motain/xpa-proto-files-web/lib/types/xpa";
import type { XpaComponentContentType } from "@/types/xpa";

import { xpaLayoutContainerClassName } from "./XpaLayout.constants";
import { XpaMemoisedComponent } from "./XpaMemoisedComponent";
import styles from "./XpaLayout.module.scss";
import { XpaLayoutHead } from "./XpaLayoutHead";
import { VisibilityAlert } from "@/components/visibility-alert/VisibilityAlert";
import type { UpdateLayoutFunction } from "@/utils/useUpdateLayout";
import { isNonNullable } from "@/types/isNonNullable";

export type XpaSupportedComponentsResolverProps = FC<{
  contentType: XpaComponentContentType;
  uiKey: string;
  onPagination?: UpdateLayoutFunction;
}>;

interface XpaLayoutProps {
  layout: Layout;
  XpaSupportedComponentsResolver: XpaSupportedComponentsResolverProps;
  shouldMemoComponents?: boolean;
  containersCountLimit?: number;
  updateLayout?: UpdateLayoutFunction;
}

/**
 * The root component for most of the XPA pages driven by our XPA server.
 * @param layout data from the XPA server
 * @param XpaSupportedComponentsResolver component that renders relevant
 * component for every stream. Due to performance reasons, we don't want to
 * load code for all components on every page.
 */
export const XpaLayout: FC<XpaLayoutProps> = ({
  layout: { pageTitle, linkTags, metaTags, jsonLd, containers },
  shouldMemoComponents = false,
  XpaSupportedComponentsResolver,
  containersCountLimit, // if no limit is set, we use the whole array
  updateLayout,
}) => {
  /*
   * This use reducer is a hack to reduce the number of containers rendered on some pages, remove it and everything
   * related to it when the pagination is implemented or no page is using containersCountLimit
   */
  const [containersLimit, removeLimit] = useReducer(
    () => containers.length,
    isNonNullable(containersCountLimit)
      ? containersCountLimit
      : containers.length,
  );

  /*
   * This useState is part of the hack above, remove it when the pagination is implemented or no page is using
   * containersCountLimit, using containers should be enough
   */
  const [containersArray, setContainersArray] = useState(
    isNonNullable(containersCountLimit)
      ? containers.slice(0, containersLimit)
      : containers,
  );

  const progressBarRef = useRef<HTMLDivElement | null>(null);
  const onRequestInterceptor = useCallback(
    (e: CustomEvent<{ requestsInProgress: number }>) => {
      if (progressBarRef.current?.style) {
        progressBarRef.current.style.display = e.detail.requestsInProgress
          ? "block"
          : "none";
      }
    },
    [],
  );

  useEffect(() => {
    document.addEventListener(
      REQUEST_INTERCEPTOR_EVENT_NAME,
      onRequestInterceptor,
    );

    return () => {
      document.removeEventListener(
        REQUEST_INTERCEPTOR_EVENT_NAME,
        onRequestInterceptor,
      );
    };
  }, [onRequestInterceptor]);

  /*
   * This useEffect is part of the hack above, remove it when the pagination is implemented or no page is using
   * containersCountLimit
   */
  useEffect(() => {
    setContainersArray(
      isNonNullable(containersCountLimit)
        ? containers.slice(0, containersLimit)
        : containers,
    );
  }, [containers, containersCountLimit, containersLimit]);

  return (
    <div className={styles.xpaLayout}>
      <div
        className={styles.pageLoadingProgressBarContainer}
        ref={progressBarRef}
      >
        <LoadingProgressBar />
      </div>
      <XpaLayoutHead
        pageTitle={pageTitle}
        metaTags={metaTags}
        linkTags={linkTags}
        jsonLd={jsonLd}
      />

      <h1 className="screen-reader-only">{pageTitle}</h1>

      {containersArray.map((container) => {
        if (container.type === undefined) {
          return null;
        }

        if (container.type.$case === "fullWidth") {
          if (container.type.fullWidth.component?.contentType === undefined) {
            return null;
          }

          return (
            <div
              className={classNames(
                // global class names allow to add additional styles/or rewrite existing rules of containers on page level
                // Example in README.md file Styling section
                xpaLayoutContainerClassName,
                styles.xpaLayoutContainerFullWidth,
                `xpaLayoutContainerFullWidth--${container.type.fullWidth.component.contentType.$case}`,
              )}
              key={container.uiKey}
            >
              <div
                className={classNames(
                  styles.xpaLayoutContainerComponentResolver,
                  `xpaLayoutContainerComponentResolver--${container.type.fullWidth.component.contentType.$case}`,
                )}
              >
                {shouldMemoComponents ? (
                  <XpaMemoisedComponent
                    key={container.type.fullWidth.component.uiKey}
                    contentType={container.type.fullWidth.component.contentType}
                    uiKey={container.uiKey}
                    XpaSupportedComponentsResolver={
                      XpaSupportedComponentsResolver
                    }
                    updateLayout={updateLayout}
                  />
                ) : (
                  <XpaSupportedComponentsResolver
                    contentType={container.type.fullWidth.component.contentType}
                    uiKey={container.uiKey}
                    onPagination={updateLayout}
                  />
                )}
              </div>
            </div>
          );
        }

        return (
          <div
            className={classNames(
              xpaLayoutContainerClassName,
              styles.xpaLayoutContainerGrid,
            )}
            key={container.uiKey}
          >
            {container.type.grid.items.map((item) => {
              return (
                <div
                  className={classNames(styles.xpaLayoutContainerGridItem, {
                    [String(styles.xpaLayoutContainerGridItemMobileHidden)]:
                      item.config?.mobile === undefined,

                    [String(
                      styles.xpaLayoutContainerGridItemTabletPortraitHidden,
                    )]: item.config?.tabletPortrait === undefined,

                    [String(
                      styles.xpaLayoutContainerGridItemTabletLandscapeHidden,
                    )]: item.config?.tabletLandscape === undefined,

                    [String(styles.xpaLayoutContainerGridItemDesktopHidden)]:
                      item.config?.desktop === undefined,
                  })}
                  style={{
                    "--grid-column-span-mobile": item.config?.mobile?.column,
                    "--grid-row-span-mobile": item.config?.mobile?.row,
                    "--grid-column-span-tablet_portrait":
                      item.config?.tabletPortrait?.column,
                    "--grid-row-span-tablet_portrait":
                      item.config?.tabletPortrait?.row,
                    "--grid-column-span-tablet_landscape":
                      item.config?.tabletLandscape?.column,
                    "--grid-row-span-tablet_landscape":
                      item.config?.tabletLandscape?.row,
                    "--grid-column-span-desktop": item.config?.desktop?.column,
                    "--grid-row-span-desktop": item.config?.desktop?.row,
                  }}
                  key={item.uiKey}
                >
                  <div
                    className={classNames(
                      styles.xpaLayoutContainerGridItemComponents,
                      {
                        [String(
                          styles.xpaLayoutContainerGridItemComponentsMobileSticky,
                        )]: item.config?.mobile?.isSticky,

                        [String(
                          styles.xpaLayoutContainerGridItemComponentsTabletPortraitSticky,
                        )]: item.config?.tabletPortrait?.isSticky,

                        [String(
                          styles.xpaLayoutContainerGridItemComponentsTabletLandscapeSticky,
                        )]: item.config?.tabletLandscape?.isSticky,

                        [String(
                          styles.xpaLayoutContainerGridItemComponentsDesktopSticky,
                        )]: item.config?.desktop?.isSticky,
                      },
                    )}
                  >
                    {item.components.map((component) => {
                      if (component.contentType === undefined) {
                        return null;
                      }

                      if (shouldMemoComponents) {
                        return (
                          <XpaMemoisedComponent
                            key={component.uiKey}
                            contentType={component.contentType}
                            uiKey={component.uiKey}
                            XpaSupportedComponentsResolver={
                              XpaSupportedComponentsResolver
                            }
                          />
                        );
                      }

                      return (
                        <XpaSupportedComponentsResolver
                          key={component.uiKey}
                          contentType={component.contentType}
                          uiKey={component.uiKey}
                          onPagination={updateLayout}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
      {isNonNullable(containersCountLimit) && (
        <VisibilityAlert onVisible={removeLimit}></VisibilityAlert>
      )}
    </div>
  );
};
