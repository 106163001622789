import type { Reader } from "protobufjs";

import type { Layout as LayoutType } from "@motain/xpa-proto-files-web/lib/types/xpa";
import { isNullable } from "@/types/isNullable";

type LayoutDecoder = (
  input: Uint8Array | Reader,
  length?: number,
) => LayoutType;

let decoder: LayoutDecoder | undefined;

export const lazyImportLayoutDecoder = async (): Promise<LayoutDecoder> => {
  if (isNullable(decoder)) {
    const {
      Layout: { decode },
    } = await import("@motain/xpa-proto-files-web/lib/runtime/xpa");

    decoder = decode;
  }

  return decoder;
};
