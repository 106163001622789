import type { FC } from "react";
import useTranslation from "next-translate/useTranslation";
import { ImageWithFallback } from "@/components/image/ImageWithFallback";

export const VerifiedIcon: FC = () => {
  const { t } = useTranslation("web-payments");

  return (
    <ImageWithFallback
      src="https://images.onefootball.com/cw/icons/verified-icon-light.svg"
      darkModeSrc="https://images.onefootball.com/cw/icons/verified-icon-dark.svg"
      alt={t`VERIFY_ICON_ALT`}
      width={26}
      height={26}
    />
  );
};
