import type { FC } from "react";
import { useEffect, useReducer, useRef } from "react";
import { isNonNullable } from "@/types/isNonNullable";
import { notifyWhenInViewport } from "@/utils/notify-when-in-viewport";

interface VisibilityAlertProp {
  onVisible: () => void;
}
export const VisibilityAlert: FC<VisibilityAlertProp> = (props) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [hasBeenExecuted, toggleHasBeenExecuted] = useReducer(
    () => true,
    false,
  );

  useEffect(() => {
    if (!hasBeenExecuted && isNonNullable(ref.current)) {
      toggleHasBeenExecuted();

      notifyWhenInViewport(ref.current)
        .then(() => {
          props.onVisible();
        })
        .catch(void 0);
    }
  }, [props, ref, hasBeenExecuted]);

  return (
    <div style={{ margin: 0 }} data-testid="visibilityAlert" ref={ref}></div>
  );
};
