import React from "react";
import useTranslation from "next-translate/useTranslation";
import type { RelatedVideosItem } from "@motain/xpa-proto-files-web/lib/types/related_videos";
import { trackingService } from "@/modules/analytics";
import { EventType } from "@motain/xpa-proto-files-web/lib/types/tracking";
import styles from "./VideoTeaser.module.scss";
import { DurationLabel } from "@/components/duration-label/DurationLabel";
import { ImageWithFallback } from "@/components/image/ImageWithFallback";
import { isNonNullable } from "@/types/isNonNullable";
import type { VideoGalleryItem } from "@motain/xpa-proto-files-web/lib/types/video_gallery";
import { getImageSourceSets } from "@/utils/helpers/getImageSourceSets";
import { RELATED_VIDEO_SOURCE_SETS_RECORD } from "./VideoTeaser.constants";
import { ImageWithSets } from "@/components/image-with-sets/ImageWithSets";
import type { Immutable } from "@/types/immutable";
import { VerifiedIcon } from "@/components/verified-icon/VerifiedIcon";
import classNames from "classnames";
import Link from "next/link";

export const VideoTeaser: React.FC<
  NonNullable<Immutable<RelatedVideosItem | VideoGalleryItem>> & {
    titleClassName?: string;
  }
> = ({
  duration,
  imageObject,
  link,
  providerIsVerified,
  providerName,
  title,
  trackingEvents,
  titleClassName = "title-8-bold",
  isLiveClipTeaser,
}) => {
  const { t } = useTranslation("web-payments");

  return (
    <Link
      href={link}
      onClick={() => {
        trackingService.sendXpaTracking(trackingEvents, EventType.EVENT_CLICK);
      }}
      className={styles.videoTeaser}
    >
      <article className={styles.videoTeaserContent}>
        <div className={styles.videoTeaserImageWrapper}>
          {isNonNullable(imageObject) && (
            <ImageWithSets
              image={getImageSourceSets(
                RELATED_VIDEO_SOURCE_SETS_RECORD,
                imageObject.path,
              )}
              isPreloaded={imageObject.isPreloaded}
              alt={imageObject.alt}
              classNames={styles.videoTeaserImage}
            />
          )}
          <DurationLabel
            duration={duration}
            className={styles.videoTeaserDuration}
          />
          <div className={styles.videoTeaserPlayVideoIcon}>
            <ImageWithFallback
              width={60}
              height={60}
              alt={t`PLAY_ICON_ALT_TEXT`}
              src="https://images.onefootball.com/cw/icons/play_icon.svg"
            />
          </div>
        </div>
        {!isLiveClipTeaser && (
          <>
            <header
              className={classNames(styles.videoTeaserTitle, titleClassName)}
            >
              {title}
            </header>
            <footer className={styles.videoTeaserInfo}>
              <span className={styles.videoTeaserPublisher}>
                {providerName}
              </span>
              {isNonNullable(providerIsVerified) && <VerifiedIcon />}
            </footer>
          </>
        )}
      </article>
    </Link>
  );
};
