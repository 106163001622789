export const notifyWhenInViewport = (
  element: HTMLElement,
  options?: IntersectionObserverInit,
): Promise<boolean> => {
  return new Promise<boolean>((resolve) => {
    const intersectionObserver = new IntersectionObserver((entries) => {
      const isIntersecting = entries.every((entry) => entry.isIntersecting);

      if (isIntersecting) {
        resolve(isIntersecting);
        intersectionObserver.disconnect();
      }
    }, options);

    intersectionObserver.observe(element);
  });
};
