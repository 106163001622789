import type { FC } from "react";
import type { XpaComponentContentType } from "@/types/xpa";
import { memo } from "react";
import type { XpaSupportedComponentsResolverProps } from "@/components/xpa-layout/XpaLayout";
import type { UpdateLayoutFunction } from "@/utils/useUpdateLayout";

interface XpaMemoisedComponentProps {
  contentType: XpaComponentContentType;
  uiKey: string;
  XpaSupportedComponentsResolver: XpaSupportedComponentsResolverProps;
  updateLayout?: UpdateLayoutFunction;
}

const arePropsEqual = (
  prevProps: XpaMemoisedComponentProps,
  nextProps: XpaMemoisedComponentProps,
): boolean =>
  prevProps.contentType.$case === nextProps.contentType.$case &&
  prevProps.uiKey === nextProps.uiKey;

export const XpaMemoisedComponent: FC<XpaMemoisedComponentProps> = memo(
  ({ contentType, uiKey, XpaSupportedComponentsResolver, updateLayout }) => (
    <XpaSupportedComponentsResolver
      uiKey={uiKey}
      contentType={contentType}
      onPagination={updateLayout}
    />
  ),
  arePropsEqual,
);

XpaMemoisedComponent.displayName = "XpaMemoisedComponent";
